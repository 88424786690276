import React, { useEffect, useState } from "react";
import {
  AllEmployeesHeading,
  ReportFilterBody,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
  TransactionGrid,
} from "../ReportFilterPageStyles";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonModal, CustomButton, CustomDateField, CustomSelectField, Loader } from "../../../components";
import { useAppSelector } from "../../../_app";
import { Notify, Utils } from "../../../utils";
import { ReportService } from "../../../_service/ReportService";
import XLSX from 'xlsx';
import { Constant } from "../../../config";
import styled from "styled-components";
import EmployeeListModal from "../EmployeeListModal/EmployeeListModal";
import cloneDeep from "lodash.clonedeep";
import { LoaderContainer } from "../../../CommonStyles";
import moment from "moment";


const MonthlyReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);
  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const generateYearArray = () => {
    const currentYear = new Date().getFullYear();
    const yearArray = [];
  
    for (let year = currentYear - 5; year <= currentYear + 11; year++) {
      yearArray.push(year);
    }
  
    return yearArray;
  };

  const monthsOptions = Utils.MONTH_OPTIONS;
  const yearOptions = generateYearArray();
  // const [month, setMonth] = useState<string>("1");
  const [month, setMonth] = useState<string>(
    `${moment(firstDayOfLastMonth).month() + 1}`
  );
  // const [year, setYear] = useState<string>(`${date.getFullYear()}`);
  const [year, setYear] = useState<string>(
    `${moment(firstDayOfLastMonth).year()}`
  );


  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [isLoading, setIsLoading] = useState(true);

  const [reportData, setReportData] = useState<any>({});

  const companyId =
    useAppSelector((state) => state.user.defaultCompnayId) || "";
  let reportCode = location?.pathname.split("/")?.pop() || "";

  const companyName = 
  useAppSelector((state) => state.user.defaultCompany) || "";

  const [statusValue, setStatusValue] = useState<string>("1");
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);

  const getData = async (onlyReports:boolean) => {
    if (companyId) {
      setIsLoading(() => true);
      try {
        const res = await ReportService._getCustomReport(
          reportCode,
          companyId,
          fromDate,
          toDate,
          selectedEmployeeList?.length > 0
            ? selectedEmployeeList?.join(',')
            : ""
        );
        if (res.status === 200) {
          let data = res?.data?.data;

          if (!res?.data?.data?.is_custom_report_settings) {
            navigate("/dashboard/manage-report");
          }

          if(onlyReports){
            setReportData(res?.data?.data);
          } else {
            setReportData(res?.data?.data);
            setEmployeeList(res?.data?.data?.employee_list);
          }
        }
      } catch (e: any) {
        Notify("Someting went wrong", 0)
      }
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    setStatusValue("1")
    getData(false)
  }, [fromDate, toDate, companyId,  ]);


  useEffect(()=>{

    if(employeeList?.active?.length > 0 || employeeList?.inactive?.length > 0) {
      let data =  statusValue == "0" ?
      [...employeeList?.active, ...employeeList?.inactive]?.map((emp: any) => emp.id) 
      : statusValue == "1" ? [...employeeList?.active]?.map((emp: any) => emp.id) 
      : [...employeeList?.inactive]?.map((emp: any) => emp.id);
      setSelectedEmployeeList(data);
    }
  },[statusValue, employeeList])

  useEffect(() => {
    if(!showEmployeeModal && selectedEmployeeList?.length >= 1){
      getData(true);
    }
  }, [selectedEmployeeList, showEmployeeModal])

  const downloadExcel = () => {
    const csvTitle = [
      [`${reportData?.report_meta_data?.company_name}`],
      ["Monthly Report"],
      [
        "Period",
        `${Utils._dateToShow(fromDate)} to ${Utils._dateToShow(toDate)}`,
      ],
      ["Number of Employees:", reportData?.report_meta_data?.no_of_employees],
    ];

    let valueArr = reportData?.report_data?.map( (item:any) => Object.values(item));
    let totalArr = Object.values(reportData?.report_totals)
      

    console.log("theFinalArrays", {valueArr, totalArr, csvTitle})

    const combinedData = [
      ...csvTitle,
      [],
      [...reportData?.report_headers],
      ...valueArr,
      [],
      ["TOTAL",...totalArr],
    ];

    const workbook = XLSX.utils.book_new();

    // Add a worksheet with combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, combinedWorksheet, "CombinedData");

    const numCols = 28; // Get the number of columns
    combinedWorksheet["!cols"] = Array(numCols).fill({ width: 15 });

    XLSX.writeFile(
      workbook,
      `${reportData?.report_meta_data?.company_name}_monthly_report.xlsx`,
      {
        compression: true,
      }
    );
  }


  const handleSelectAll = (status: any) => {
    const allEmpIds = status === "1" ? employeeList?.active?.map((emp: any) => emp.id) 
    : status === "2" ? employeeList?.inactive?.map((emp: any) => emp.id) 
    : [...employeeList?.active, ...employeeList?.inactive].map((emp: any) => emp.id)
    setSelectedEmployeeList([...allEmpIds]);
  };

  const handleSelectEmployee = (id: any) => {
    if (selectedEmployeeList.includes(id)) {
      let interArr = selectedEmployeeList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeList(() => interArr);
    } else {
      setSelectedEmployeeList(() => [...selectedEmployeeList, id]);
    }
  };

  const handleEmployeeSelectNone = () => {
    setSelectedEmployeeList(() => []);
  };

  console.log("theCal", month, year)

  const getMonthRange = (selectedMonth: string, selectedYear: string) => {

    const monthIndex = parseInt(selectedMonth) - 1;
    const intYear = parseInt(selectedYear);

    const firstDay = new Date(intYear, monthIndex, 1);
    const lastDay = new Date(intYear, monthIndex + 1, 0);

    setFromDate(moment(firstDay).format("YYYY-MM-DD"));
    setToDate(moment(lastDay).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    getMonthRange(month, year);
  }, [month, year]);


  if(isLoading) {
    return <LoaderContainer>
    <Loader />
  </LoaderContainer>
  }


  return (
    <>
      <H3Heading>{"Monthly Report"}</H3Heading>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          {/* <ReportHeading>{pathToHeading[location.pathname]}</ReportHeading> */}
        </ReportHeadingContainer>

        <ReportFilterBody>

        <div>
            <ReportSectionHeading>Filters</ReportSectionHeading>
            <div style={{ display: "flex", gap: 50, marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <Container>
                  <Text>Current Status </Text>
                  <Select
                    value={statusValue}
                    onChange={(e:any) => {
                      setStatusValue(e.target.value);
                      handleSelectAll(e.target.value)
                    }}
                  >
                    <option value={"0"}>{Constant.common.all}</option>
                    <option value={"2"}>{Constant.common.inactive}</option>
                    <option value={"1"}>{Constant.common.active}</option>
                  </Select>
                </Container>
              </div>
              <div style={{ display: "flex" }}>
                <Container style={{ marginBottom: 20 }}>
                  <Text>Employees </Text>
                  <Information onClick={() => setShowEmployeeModal(() => true)}>
                    {selectedEmployeeList?.length === 0
                      ? "None"
                      : selectedEmployeeList?.length ===
                        employeeList?.active?.length +
                          employeeList?.inactive?.length
                      ? "All"
                      : `${selectedEmployeeList?.length} of ${ statusValue == "0" ?
                          (employeeList?.active?.length + employeeList?.inactive?.length) : statusValue == "1" ? employeeList?.active?.length : employeeList?.inactive?.length
                        }`}
                  </Information>
                </Container>
              </div>
            </div>
          </div>
          <ReportHorizontalSeparator style={{ marginTop: 6 }} />
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            {/* <div style={{ display: "flex", width: "50%" }}>
              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="40px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
              />
            </div> */}
             <div
              style={{
                display: "flex",
                gap: 20,
                width: "100%",
                alignItems: "center",
              }}
            >
              <p style={{ color: "#495057", fontSize: "1rem" }}>
                Select Month and Year
              </p>
              <div>
                <CustomSelectField
                  value={month}
                  onChangeOption={(value) => setMonth(value)}
                  option={monthsOptions.map((month) => ({
                    key: month.key,
                    value: month.value,
                  }))}
                  disabled={isLoading}
                  isDate={true}
                />
              </div>

              <div>
                <CustomSelectField
                  value={year}
                  onChangeOption={(value) => setYear(value)}
                  option={yearOptions.map((year) => ({
                    key: year,
                    value: year,
                  }))}
                  disabled={isLoading}
                  isDate={true}
                />
              </div>

              <div style={{marginLeft: 'auto'}}>
                <CustomButton
                  width="150px"
                  title="Show Excel"
                  onClick={selectedEmployeeList?.length >= 1 ? downloadExcel : () => Notify("Please select at least one employee", 0)}
                  // disable={!selectedEmployeeList?.legth}
                />
              </div>

              
            </div>
          </div>
          <ReportHorizontalSeparator />

          <ReportSectionHeading>Employees</ReportSectionHeading>

          <AllEmployeesHeading>
            {selectedEmployeeList?.length === 0
              ? "No "
              : selectedEmployeeList?.length ===
                employeeList?.active?.length +
                  employeeList?.inactive?.length
              ? `All (${selectedEmployeeList?.length}) `
              : `${selectedEmployeeList?.length} of ${
                statusValue == "0" ?
                (employeeList?.active?.length + employeeList?.inactive?.length) : statusValue == "1" ? employeeList?.active?.length : employeeList?.inactive?.length
                } `}
            employee(s) selected
          </AllEmployeesHeading>

          <ReportHorizontalSeparator />

          <ReportSectionHeading>Transactions</ReportSectionHeading>

          <div>
            {Object.entries(reportData?.parameters || {})?.map((item: any) => (
              <>
                {item[1]?.length >= 1 && <div style={{ display: "flex", gap: 10, margin: "20px 0" }}>
                  <h3
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#333333",
                    }}
                  >
                    {Utils.formateStringWithUnderscore(item[0])}
                  </h3>
                </div>}
                <TransactionGrid>
                  {item[1]?.length >= 1 && item[1]?.map((x: any) => (
                    <div
                      style={{
                        display: "flex",
                        gap: 20,
                        color: "#333333",
                      }}
                    >
                      <input type="checkbox" checked={x?.is_checked} />
                      <p>{x?.name}</p>
                    </div>
                  ))}
                </TransactionGrid>
              </>
            ))}
          </div>
          <ReportHorizontalSeparator />
          <CustomButton
              width="150px"
              title="Show Excel"
              onClick={selectedEmployeeList?.length >= 1 ? downloadExcel : () => Notify("Please select at least one employee", 0)}
              // disable={!selectedEmployeeList?.legth}
            />
        </ReportFilterBody>
      </ReportFilterContainer>

      <CommonModal
        handleClose={() => {
          setShowEmployeeModal(false);
          setSelectedEmployeeList([...selectedEmployeeList]);
        }}
        open={showEmployeeModal}
        updateData={() => setShowEmployeeModal(false)}
        body={
          <EmployeeListModal
            employeeList={statusValue === '1' ? employeeList?.active : statusValue === '2' ? employeeList?.inactive : [...employeeList?.active, ...employeeList?.inactive]}
            handleSelectAll={() => handleSelectAll(statusValue)}
            handleSelectNone={handleEmployeeSelectNone}
            selectedEmployeeList={selectedEmployeeList}
            handleSelectEmployee={handleSelectEmployee}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />

    </>
  );
};

export default MonthlyReport;



const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;

const Information = styled.div`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
`;


// All - 0
// Active - 1
// Inactive - 2