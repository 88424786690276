import { NavLink, useNavigate } from "react-router-dom";
import { CommonModal, CustomButton, Loader } from "../../components";
import {
  CPFReturnContainer,
  CPFReturnHeading,
  CPFTable,
  CPFTextHeadingColumn,
  MonthContainer,
  MonthHeading,
  MonthHeadingContainer,
} from "./CPFFilingStyles";
import { useEffect, useState } from "react";
import { CPFService } from "../../_service/CPFService";
import { useAppSelector } from "../../_app";
import { Notify, Utils } from "../../utils";
import { LoaderContainer } from "../../CommonStyles";
import { Constant } from "../../config";
import { TableHeader } from "../../components/table";
import AddDownloadDetails from "./CPF/AddDownloadDetails";
import CpfModal from "./CPF/CPFModal";

const CPFReports = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cpfList, setCpfList] = useState<any>({});
  const [isDownloadFTP, setIsDownloadFTP] = useState<any>(false);
  const [selectedCpf, setSelectedCpf] = useState<any>();
  const [serialNo, setSerialNo] = useState("01");
  const [advancedCode, setAdvancedCode] = useState("01");
  const [pdfData, setPdfData] = useState<any>([]);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [periodString, setPeriodString] = useState<string>("");

  const user = useAppSelector((state) => state.user);

  const handleSerialNoChange = (val: string) => setSerialNo(() => val);
  const handleAdvancedCodeChange = (val: string) => setAdvancedCode(() => val);

  /**
   * Function to toggle "opened" status of Cpf Details container
   */
  const toggleOpenStatus = (cpfMonth: string) => {
    setCpfList((prevList: any) => {
      const updatedList = { ...prevList };

      updatedList[cpfMonth].isOpened = !updatedList[cpfMonth].isOpened;
      return updatedList;
    });
  };

  /**
   * Function to get the details of all CPF list of past 24 months
   */
  const getCPFList = async () => {
    setIsLoading(true);
    try {
      const { data, status } = await CPFService._getCPFList(
        user.defaultCompnayId
      );
      if (status === 200) {
        const updatedData: any = {};

        for (const cpfMonth in data) {
          if (data.hasOwnProperty(cpfMonth)) {
            updatedData[cpfMonth] = { ...data[cpfMonth], isOpened: true };
          }
        }
        setCpfList(() => updatedData);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(false);
  };

  /**
   * Function to get the update finalized status of e-CPF
   */
  const updateCpfStatus = async (cpfId: any) => {
    try {
      const { status } = await CPFService._updateCPFStatus(
        user.defaultCompnayId,
        cpfId
      );

      if (status === 200) {
        Notify("CPF Finalized Successfully", 1);
        getCPFList();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const tableHeader = [
    {
      name: "Status",
      width: "20%",
    },
    {
      name: "Date Submitted",
      width: "20%",
    },
    {
      name: "Preview Summary CPF-EZPay",
      width: "20%",
    },
    {
      name: "Download e-CPF-EZPay",
      width: "20%",
    },
    {
      width: "20%",
    },
  ];

  const handleDownload = async () => {
    try {
      const dataToSend = {
        sno: serialNo,
        adv_code: advancedCode,
      };
      const { data, status } = await CPFService._updateCPFPenalty(
        selectedCpf,
        dataToSend
      );

      if (status === 200) {
        const a = document.createElement("a");
        a.href = data.data.ftp_file;
        a.click();
        closeDownloadModal();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const closeDownloadModal = () => {
    setIsDownloadFTP(() => false);
    setSerialNo(() => "01");
    setAdvancedCode(() => "01");
  };

  const handleFTPClick = (cpfRet: any) => {
    setSelectedCpf(() => cpfRet.id);
    setIsDownloadFTP(() => true);
  };

  const handlePdfClick = async (cpfId: any, cpfMonth: any) => {
    try {
      const { status, data } = await CPFService._getPdfData(cpfId);
      if (status === 200) {
        setPdfData(() => data.data);
        setShowPdf(() => true);
        setPeriodString(() => Utils.getMonthRange(cpfMonth));
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getCPFList();
  }, []);
  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        Object.keys(cpfList).map((cpfMonth: any) => (
          <MonthContainer>
            <MonthHeadingContainer onClick={() => toggleOpenStatus(cpfMonth)}>
              <MonthHeading>{cpfMonth}</MonthHeading>
            </MonthHeadingContainer>
            {cpfList[cpfMonth].isOpened && (
              <CPFReturnContainer>
                <CPFReturnHeading>
                  <p>Create CPF Return </p>
                  {user.permission === "full" && (
                    <>
                      -
                      <NavLink
                        to="new"
                        state={{
                          month: cpfMonth.split(" ")[0],
                          year: cpfMonth.split(" ").pop(),
                        }}
                      >
                        Inputs
                      </NavLink>
                    </>
                  )}
                </CPFReturnHeading>
                <div>
                  <CPFTable>
                    <TableHeader headerColumns={tableHeader} />
                    <tbody>
                      {cpfList[cpfMonth].cpf_returns
                        .sort((a: any, b: any) => b.id - a.id)
                        .map((cpfReturn: any) => (
                          <tr>
                            <CPFTextHeadingColumn>
                              <p style={{ fontSize: "15px" }}>
                                {cpfReturn.status === "new"
                                  ? "Working"
                                  : cpfReturn.status === "finalized"
                                  ? "e-file submitted to CPF portal"
                                  : cpfReturn.status}
                              </p>
                            </CPFTextHeadingColumn>
                            <CPFTextHeadingColumn>
                              <p style={{ fontSize: "15px" }}>
                                {cpfReturn.date_of_finalized
                                  ? Utils._dateToShow(
                                      cpfReturn.date_of_finalized
                                    )
                                  : "Pending"}
                              </p>
                            </CPFTextHeadingColumn>
                            <td style={{ textAlign: "center" }}>
                              <a
                                onClick={() =>
                                  handlePdfClick(cpfReturn.id, cpfMonth)
                                }
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  color: "#0000ee",
                                }}
                              >
                                PDF
                              </a>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <a
                                style={{
                                  color: "#0000EE",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleFTPClick(cpfReturn)}
                              >
                                FTP File
                              </a>
                            </td>
                            {cpfReturn.status === "new" &&
                              user.permission === "full" && (
                                <td>
                                  <CustomButton
                                    onClick={() =>
                                      updateCpfStatus(cpfReturn.id)
                                    }
                                    title="Submit e-CPF FTP file"
                                    width="175px"
                                    styles={{ fontSize: "15px" }}
                                  />
                                </td>
                              )}
                          </tr>
                        ))}
                    </tbody>
                  </CPFTable>
                </div>
              </CPFReturnContainer>
            )}
          </MonthContainer>
        ))
      )}
      <CommonModal
        heading="Download e-CPF EZ Pay - FTP file"
        open={isDownloadFTP}
        handleClose={closeDownloadModal}
        body={
          <AddDownloadDetails
            serialNo={serialNo}
            advancedCode={advancedCode}
            handleSerialNoChange={handleSerialNoChange}
            handleAdvancedCodeChange={handleAdvancedCodeChange}
          />
        }
        conformButtonText="Download"
        cancelButtonText="Cancel"
        updateData={handleDownload}
      />
      {showPdf && (
        <CpfModal
          isOpen={showPdf}
          handleClose={() => setShowPdf(false)}
          documents={pdfData}
          period={periodString}
        />
      )}
    </div>
  );
};

export default CPFReports;
