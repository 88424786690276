import apiClient from "./BaseService";
import { URL } from "../config";

const _getServiceCodeList = async () => apiClient.get(URL.SERVICE_CODES);
const _addServiceCode = async (data: any) => {
    return apiClient.post(URL.SERVICE_CODES, data)
}
const _deleteServiceCode = async (serviceId: any) => {
    return apiClient.delete(URL.SERVICE_CODES+ serviceId + '/')
}
const _editServiceCode = async (data: any, serviceId: any) => {
    return apiClient.patch(URL.SERVICE_CODES+ serviceId + '/', data)
}

const _manageService = async (data: any, action: any) => {
    return apiClient.post(URL.MANAGE_SERVICE + action, data)
}

export const ServiceCodeService = {
    _getServiceCodeList,
    _addServiceCode,
    _deleteServiceCode,
    _editServiceCode,
    _manageService
};
