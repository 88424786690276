import { useAppSelector } from "../../../_app";
import { PayrollService } from "../../../_service";
import { CustomTextField } from "../../../components";
import { CustomButton } from "../../../components/customButton";
import { useEffect, useState } from "react";
import { ServicePeriodInterface } from "../../../_types/AddEmployeeInterface";
import moment from "moment";
import { Notify } from "../../../utils";

const Probation = () => {
  const [servicePeriodInfo, setServicePeriodInfo] =
    useState<ServicePeriodInterface>({
      company: "",
      employee: "",
      is_probation_confirmed: false,
      probation_confirmed_by: "",
      probation_confirmed_date: "",
      probation_last_date: ``,
      probation_months: "",
      service_end_date: "",
      service_start_date: "",
    });

  const employeeId = useAppSelector((state) => state.employee.employee_info.id);

  const getServicePeriodInfo = async () => {
    try {
      PayrollService._getServicePeriod(employeeId).then((data) => {
        setServicePeriodInfo({ ...data?.data?.data });
      });
    } catch (error) {}
  };

  console.log("state after storing data", servicePeriodInfo);
  useEffect(() => {
    getServicePeriodInfo();
  }, [JSON.stringify(servicePeriodInfo)]);

  const handleProbationConfirmation = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setServicePeriodInfo((prevState) => ({
      ...prevState,
      is_probation_confirmed: !prevState.is_probation_confirmed,
    }));

    try {
      let res = await PayrollService._editServicePeriod(servicePeriodInfo?.id, {
        is_probation_confirmed: true,
      });

      if (res?.data?.data?.is_probation_confirmed) {
        Notify("Probation Confirmed Successfully!", 1);
        setServicePeriodInfo({...servicePeriodInfo, is_probation_confirmed: true, probation_confirmed_date: res?.data?.data?.probation_confirmed_date});
      }
    } catch {
      Notify("Something went wrong", 0);
    }
  };
  console.log(servicePeriodInfo);
  return (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      <div style={{ width: "100%", }}>
        {/* <CustomTextField
          title="Probation Months Count: "
          value={servicePeriodInfo?.probation_months || ""}
          disabled
          require={false}
        /> */}

        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          marginBottom: '1rem',
          fontSize: '1.2rem',
          color: 'rgb(71, 101, 117)'
        }}>
          <p style={{
            fontWeight: 700
          }}>{"Probation Months: "}</p>
          <p style={{
            fontSize: '1.2rem',
            color: '#000',
            fontWeight: 500
          }}>{servicePeriodInfo?.probation_months || ""}</p>
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          fontSize: '1.2rem',
          color: 'rgb(71, 101, 117)'
        }}>
          <p style={{
            fontWeight: 700
          }}>{"Last Date of Probation: "}</p>
          <p style={{
            fontSize: '1.2rem',
            color: '#000',
            fontWeight: 500
          }}>{
            (servicePeriodInfo?.probation_last_date &&
              moment(
                servicePeriodInfo?.probation_last_date?.split("T")?.[0]
              ).format("DD-MM-YYYY")) ||
            ""
          }</p>
        </div>

        {/* <CustomTextField
          title="Last Date of Probation: "
          value={
            (servicePeriodInfo?.probation_last_date &&
              moment(
                servicePeriodInfo?.probation_last_date?.split("T")?.[0]
              ).format("DD-MM-YYYY")) ||
            ""
          }
          disabled
          require={false}
        /> */}

        {servicePeriodInfo?.probation_last_date &&
          !servicePeriodInfo?.is_probation_confirmed && (
            <div
              style={{
                marginTop: "3rem",
              }}
            >
              <CustomButton
                title="Confirm"
                disable={
                  servicePeriodInfo?.is_probation_confirmed == true ||
                  !servicePeriodInfo?.probation_last_date
                }
                onClick={(e) => handleProbationConfirmation(e)}
              />
            </div>
          )}

        {servicePeriodInfo?.is_probation_confirmed && servicePeriodInfo?.probation_confirmed_date
&& <p style={{
  fontSize: '1.2rem',
  color:"#03b381",
  fontWeight: 500,
  marginTop: '3rem'
}}>{`Probation confirmed on ${moment(servicePeriodInfo?.probation_confirmed_date?.split("T")?.[0]).format("DD-MM-YYYY")}`}</p> }
      </div>
    </div>
  );
};

export default Probation;
